<template>
  <Popup
    class="edit-user"
    :modal-id="modal_id"
    :on-close="onModalClose"
    custom-class="gray-header"
    :toggle-status="toggleStatus"
  >
    <template v-slot:header>
      {{ labels.edit_user }}
    </template>

    <template v-slot:body>
      <div v-if="!loading" class="edit-user__body">
        <div class="row">
          <div class="col-md-6 col-12">
            <FormulateInput
              type="text"
              name="text"
              :placeholder="labels.username"
              :label="labels.username"
              validation="required"
              error-behavior="blur"
              v-model="userdata.username"
            />
          </div>
          <div class="col-md-6 col-12">
            <FormulateInput
              type="email"
              name="email"
              :placeholder="labels.email"
              :label="labels.email"
              validation="required"
              error-behavior="blur"
              v-model="userdata.email"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-sm-6 col-12">
            <FormulateInput
              type="text"
              name="text"
              :placeholder="labels.first_name"
              error-behavior="blur"
              v-model="userdata.name.first"
            />
          </div>
          <div class="col-md-4 col-sm-6 col-12">
            <FormulateInput
              type="text"
              name="text"
              :placeholder="labels.middle_name"
              error-behavior="blur"
              v-model="userdata.name.middle"
            />
          </div>
          <div class="col-md-4 col-sm-6 col-12">
            <FormulateInput
              type="text"
              name="text"
              :placeholder="labels.last_name"
              error-behavior="blur"
              v-model="userdata.name.last"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-sm-6 col-12">
            <FormulateInput
              type="select"
              name="select"
              :placeholder="labels.country"
              :options="countries"
              v-model="userdata.address.country"
            />
          </div>
          <div class="col-md-4 col-sm-6 col-12">
            <FormulateInput
              type="text"
              name="text"
              :placeholder="labels.state"
              error-behavior="blur"
              v-model="userdata.address.state"
            />
          </div>
          <div class="col-md-4 col-sm-6 col-12">
            <FormulateInput
              type="text"
              name="text"
              :placeholder="labels.city"
              error-behavior="blur"
              v-model="userdata.address.city"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-8 col-12">
            <FormulateInput
              type="text"
              name="text"
              :placeholder="labels.street"
              error-behavior="blur"
              v-model="userdata.address.street"
            />
          </div>
          <div class="col-md-4 col-12">
            <FormulateInput
              type="text"
              name="text"
              :placeholder="labels.zip"
              error-behavior="blur"
              v-model="userdata.address.zip"
            />
          </div>
        </div>
        <div class="phones-container">
          <!-- <label>
            {{ labels.phones_list }}
          </label> -->

          <div
            v-for="(phone, index) in userdata.phone"
            :key="index"
            class="phone"
          >
            <FormulateInput
              type="text"
              name="text"
              :placeholder="labels.phone"
              error-behavior="blur"
              :disabled="true"
              v-model="userdata.phone[index]"
              :value="phone"
            />

            <Button
              :label="labels.remove"
              variant="btn-danger"
              font-weight="600"
              @click="removePhone(index)"
            />
          </div>

          <div class="phone">
            <FormulateInput
              type="text"
              name="text"
              :placeholder="labels.enter_phone_number"
              error-behavior="blur"
              v-model="newPhone"
            />

            <Button
              :label="labels.add"
              variant="btn-green"
              font-weight="600"
              @click="addPhone()"
            />
          </div>
        </div>
        <div class="row" v-if="$route.params.kind === 'admin'">
          <div class="col-12">
            <FormulateInput
              class="select-roles"
              v-model="userdata.admin_roles"
              :options="roles"
              type="checkbox"
              :label="labels.select_roles_for_user"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <FormulateInput
              type="select"
              name="select"
              :label="labels.default_language"
              :options="availableLanguages"
              v-model="userdata.defaultLanguage"
            />
          </div>
          <div class="col-12">
            <FormulateInput
              type="select"
              name="select"
              :label="labels.assigned_to_space"
              :options="availableSpaces"
              v-model="userdata.space"
            />
          </div>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <div class="edit-user__footer">
        <FormulateInput
          type="submit"
          class="btn-purple"
          :label="labels.edit_user"
          form="new-entity"
          @click="updateUser"
        />

        <Button
          :label="labels.close"
          variant="btn-outline-purple"
          font-weight="600"
          :action="onModalClose"
        />
      </div>
    </template>
  </Popup>
</template>

<script>
import Popup from "@/components/Popups/Popup";
import Button from "@/components/Buttons/Button";

import { mapState, mapActions, mapGetters } from "vuex";

export default {
  components: {
    Popup,
    Button,
  },
  data() {
    return {
      loading: true,
      modal_id: "2",
      toggleStatus: true,
      userdata: {
        address: {
          city: "",
          country: "",
          state: "",
          street: "",
          zip: "",
        },
        admin_roles: [],
        name: {
          first: "",
          last: "",
          middle: "",
        },
        phone: [],
        defaultLanguage: "en",
        space: ""
      },
      availableLanguages: {
        en: "English",
        de: "Deutsch",
      },
      availableSpaces: {},
      newPhone: "",
    };
  },
  methods: {
    ...mapActions('space', ['getSpacesList']),
    ...mapActions("user", {
      getUser: "getUser",
      editUser: "editUser",
      setAdminRole: "setAdminRole",
    }),
    ...mapActions("notification", {
      addNotification: "addNotification",
    }),
    ...mapActions("helper", {
      getCountries: "getCountries",
    }),
    loadSpaces() {
      this.getSpacesList()
        .then((res) => {
          if (!res.success) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          } else {
            for (let index = 0; index < res.data.data.length; index++) {
              const space = res.data.data[index];
              
              this.availableSpaces[space._id] = space.title;
            }
          }
        })
        .catch((err) => console.log(err));

        this.loading = false;
    },
    onModalClose() {
      this.resetLocalUserdata();

      if (this.routeBack && Object.keys(this.routeBack).length) {
        this.$router.push(this.routeBack);
      } else {
        this.$router.push({ name: "users", params: { kind: this.userKind } });
      }

      let element = document.querySelector(".modal-backdrop");
      if (element) {
        element.remove();
        document.querySelector("body").classList.remove("modal-open");
      }
    },
    loadUserData() {
      this.getUser(this.userId)
        .then((res) => {
          if (!res.success) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    updateUser() {
      this.setAdminRole({ id: this.userId, role: this.userdata.admin_roles })
        .then((res) => {
          if (res.success) {
            console.log(res);
          }
        })
        .catch((err) => console.log(err));

      this.editUser({
        id: this.userId,
        payload: this.userdata,
      })
        .then((res) => {
          if (res.success) {
            this.addNotification({
              variant: "success",
              msg: ["user_updated"],
              labels: this.labels,
            });
            this.onModalClose();
          } else {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    resetLocalUserdata() {
      this.userdata = {
        address: {
          city: "",
          country: "",
          state: "",
          street: "",
          zip: "",
        },
        admin_roles: [],
        name: {
          first: "",
          last: "",
          middle: "",
        },
        phone: [],
        defaultLanguage: "en",
      };
    },
    removePhone(index) {
      this.userdata.phone.splice(index, 1);
    },
    addPhone() {
      this.userdata.phone.push(this.newPhone);
      this.newPhone = "";
    },
  },
  beforeMount() {
    // Redirect if user don't have permissions
    if (!this.isAllowed) {
      if (this.hasPermissions(["super", "system"])) {
        this.$router.push({ name: "dashboard" });        
      }
    }
  },
  mounted() {
    this.loading = true;
    
    if (
      Object.keys(this.singleUser).length &&
      this.singleUser._id === this.userId
    ) {
      this.userdata = { ...this.userdata, ...this.singleUser };
    } else {
      this.loadUserData();
    }

    if (!Object.keys(this.countries).length) {
      this.getCountries();
    }

    this.loadSpaces();
  },
  computed: {
    ...mapGetters("helper", {
      hasPermissions: "hasPermissions",
      labels: "labels",
    }),

    isAllowed() {
      console.log(this.hasPermissions( "system"))
      console.log(this.hasPermissions("super"))
      return this.hasPermissions(["super", "system"]);
    },
    ...mapState("user", {
      singleUser: "singleUser",
    }),
    ...mapState("helper", {
      countries: "countries",
    }),
    userKind() {
      return this.$route.params.kind;
    },
    userId() {
      return this.$route.params.userId;
    },
    roles() {
      return {
        author: this.labels.author,
        reviewer: this.labels.reviewer,
        web: this.labels.web,
        system: this.labels.system,
        super: this.labels.super,
        creator: this.labels.creator,
        linker: this.labels.linker,
      };
    },
  },
  watch: {
    singleUser(newData) {
      this.userdata = { ...this.userdata, ...newData };
    },
  },
  beforeUnmount() {
    this.resetLocalUserdata();
  },
};
</script>

<style lang="scss" scoped>
.edit-user {
  .select-roles {
    &::v-deep {
      .formulate-input-wrapper {
        .formulate-input-group {
          flex-wrap: wrap;
          row-gap: 10px;
        }
      }
    }
  }
  &__header-title {
    font-family: "Oswald", sans-serif;
  }

  &__body {
    margin-top: -15px;

    div[class*="col-"] {
      margin-top: 15px;
    }

    .phones-container {
      margin-top: 15px;

      label {
        font-family: "Oswald", sans-serif;
        margin-bottom: 10px;
        display: block;
        line-height: 1.5;
        font-size: 0.9em;
        font-weight: 600;
      }

      .phone {
        display: flex;
        margin-top: 15px;
        @media (max-width: 310px) {
          flex-wrap: wrap;
          row-gap: 10px;
        }

        ::v-deep {
          .formulate-input {
            flex: 1 0 auto;
            margin-bottom: 0px;
            @media (max-width: 310px) {
              width: 100%;
            }
          }

          button {
            margin-left: 15px;
            width: 110px;
            @media (max-width: 310px) {
              margin-left: 0px;
              width: 100%;
            }
          }
        }
      }
    }

    ::v-deep .formulate-input {
      .formulate-input-wrapper {
        label {
          font-family: "Oswald", sans-serif;
          margin-bottom: 10px;
        }

        .formulate-input-element {
          max-width: 100%;
        }

        .formulate-input-group {
          display: flex;
          .formulate-input-group-item {
            margin-right: 10px;
            margin-bottom: 0;
          }
          label {
            margin-bottom: 0;
            font-family: inherit;
            font-weight: inherit;
          }
        }
      }

      &.horizontal-label-input {
        .formulate-input-wrapper {
          display: flex;
          align-items: center;
          gap: 3rem;

          .formulate-input-element {
            flex: 1 1 auto;
          }
        }
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    ::v-deep .formulate-input {
      margin-bottom: 0px !important;

      .formulate-input-element {
        button {
          margin-bottom: 0px;
          margin-right: 15px;
        }
      }
    }
  }
}
</style>
